@mixin bg_button_styles($HEIGHT, $WIDTH) {
    .ant-btn {
        background-color: var(--primary);
        // color: var(--primarywhite) !important;
        // font-size: 1.3rem;
        // font-weight: 400;
        border-radius: 5px;
        height: $HEIGHT;
        width: $WIDTH;
        border: none;

        &:hover,
        &:focus {
            background-color: var(--primary);
            color: var(--primarywhite);

        }

        a {
            color: var(-primarywhite);
        }
    }
}

@mixin border_button_styles($HEIGHT, $WIDTH) {
    .ant-btn {
        background-color: unset;
        color: var(--primary) !important;
        font-size: 1.3rem;
        font-weight: 400;
        border-radius: 5px;
        height: $HEIGHT;
        width: $WIDTH;
        border: 1.5px solid var(--primary);

        // &:hover,
        // &:focus {
        //     background-color: var(--primary);
        //     color: white;

        // }

        // a {
        //     color: white;
        // }
    }
}

@mixin paginationStyles {
    .ant-pagination {
        text-align: end;
        margin-top: 1%;

        .ant-pagination-item-active {
            border-radius: 50%;
            background-color: var(--primary);
            min-width: 2.5rem;
            height: 2.5rem;
            line-height: 2.3rem;
            font-weight: 500;

            a {
                color: var(--primarywhite);
                font-size: 1.2rem;
            }
        }

        .ant-pagination-item-link {
            border-radius: 50%;
        }

        .ant-pagination-prev {
            min-width: 2.5rem;
            height: 2.5rem;
            line-height: 2.5rem;

            .ant-pagination-item-link {
                font-size: 1rem;
            }
        }

        .ant-pagination-next {
            min-width: 2.5rem;
            height: 2.5rem;
            line-height: 2.5rem;

            .ant-pagination-item-link {
                font-size: 1rem;
            }
        }


    }

    .ant-pagination-item {
        border-color: var(--primary);
        border-radius: 50%;
        min-width: 2.5rem;
        height: 2.5rem;
        line-height: 2.3rem;
        font-weight: 500;

        a {
            color: var(--primary);
            font-size: 1.2rem;
            font-weight: 500;

        }
    }
}

@mixin tableStyles($TB_HEADER_COLOR, $TB_BODY_COLOR) {

    .ant-table-wrapper {
        border-spacing: 0rem 0rem !important;
        .ant-table-tbody > tr:hover  {
            box-shadow: 0 0 6px #00000029;
          }
        .ant-table {
            background-color: unset;

            table {
                border-spacing: 0 1rem !important;
            }

            .ant-table-thead>tr>th {
                background-color: $TB_HEADER_COLOR;
                font-size: 1.3rem;
                font-weight: 500;
                // padding: 1.2%;
                padding: 1.9rem 1rem 1.9rem 2.6rem;
            }

            .ant-table-thead>tr>th:first-child {
                border-radius: 5px 0 0 5px;
            }

            .ant-table-thead>tr>th:last-child {
                border-radius: 0 5px 5px 0;
            }

            .ant-table-tbody>tr>td {
                // border-bottom: 1px solid rgba(0, 0, 0, .06);
                background-color: $TB_BODY_COLOR;
                font-size: 1.3rem;
                font-weight: 400;
                // padding: 1.2%;
                padding: 1.3rem 1rem 1.3rem 2.6rem;

            }

            .ant-table-tbody>tr>td:last-child {
                border-radius: 0 5px 5px 0;
            }

            .ant-table-tbody>tr>td:first-child {
                border-radius: 5px 0 0 5px;
            }

            .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
                display: none;
            }


        }
    }



}

@mixin tabStyles {
    .ant-tabs-top>.ant-tabs-nav:before {
        border: none !important;
    }

    .ant-tabs {

        .ant-tabs-nav {
            .ant-tabs-tab {
                font-size: 1.4rem;
                font-weight: 500;
                color: black;
                // padding: 3% 0% 2% 0%;
                margin-right: 4rem;
                padding: 0.9rem 0rem;

                .ant-tabs-tab-btn {
                    //opacity: 0.4;
                    color: #393939;
                    font-weight: 500;
                }

            }

            .ant-tabs-ink-bar {
                height: 0.25rem;
                border-radius: 10px;
            }


        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--primary);
            font-size: 1.4rem;
            font-weight: 500;
            opacity: 1;

        }

        .ant-tabs-content {
            // padding: 0.5% 0% 1% 0%;
            padding: 0% 0% 1% 0%;

        }

    }
}

@mixin Select_Styles {

    .ant-select {
        color: black;
        width: 100%;
        font-size: 1.3rem;

        .ant-select-arrow {
            color: var(--black);
            font-size: 1rem;
            margin-top: -4px;
            margin-left: -4px;
        }
    }

    .ant-select-single:not(.ant-select-customize-input) {
        font-weight: 300;
        // border: 2px solid red;

        .ant-select-selector {
            width: 100%;
            height: 3.3rem !important;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            padding: 0px 2px 1px 6px;

            .ant-select-selection-placeholder {
                line-height: 3.4rem;
                font-size: 1.3rem;
            }

            .ant-select-selection-item {
                line-height: 3.4rem;
                font-size: 1.3rem;
                font-weight: 300;
            }
        }
    }

    .ant-select-dropdown {
        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            // background-color: #f5f5f5;
        }

        .ant-select-item {
            font-size: 1.2rem;
            font-weight: 400;
            color: var(--black);
        }
        .ant-select-item-option-active {
            border-left: 3px solid var(--primary);
        }
        
        
    }

}

@mixin Calender_Styles {
    .ant-picker {
        border: 1px solid #d9d9d9 !important;
        color: var(--black);
        border-radius: 5px;
        height: 3.3rem;
        padding: 0px 14px 1px 6px;
        width: 100% !important;

        &:hover {
            border-color: var(--primary) !important;
        }
    }

    .ant-picker-focused {
        box-shadow: 0px 0px 2px var(--primary) !important;
        // box-shadow: unset !important;
    }
    .ant-picker-input>input {
        font-size: 1.3rem;
        font-weight: 300;
    }

    .ant-picker-suffix {
        .anticon {
            color: var(--black);
            font-size: 1.2rem;
        }
    }

    .ant-picker-clear {
        .anticon {
            color: var(--black);
        }
    }

    .ant-picker-panel-container {
        .ant-picker-date-panel {
            .ant-picker-content th {
                font-size: 1.3rem;
                font-weight: 400;
                color: var(--black);
            }

            .ant-picker-cell .ant-picker-cell-inner {
                min-width: 23px;
                height: 22px;
                font-size: 1.2rem;
                line-height: 22px;
                border-radius: 50% !important;
            }

            .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
                border-radius: 50%;
            }

            .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
                background-color: var(--primary);
                color: var(--primarywhite);
            }
        }

        .ant-picker-header>button {
            font-size: 13px;

            &:hover {
                color: var(--primary);
            }
        }

        .ant-picker-header-view {
            button {
                font-size: 1.4rem;
                font-weight: 500;
            }
        }

    }

}

@mixin Modal_Styles($width) {
    .ant-modal {
        width: $width;
        z-index: 100000000000000 !important;

        .ant-modal-content {
            background-color: white;
            border-radius: 8px;

            .ant-modal-close-x {

                // line-height: 54px;
                .anticon {}
            }

            .ant-modal-header {
                padding: 13px 24px;
                background-color: white;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom: 1px solid rgba(0, 0, 0, .06);

                .ant-modal-title {
                    text-transform: capitalize;
                    font-size: 1.4rem;
                    color: black;
                    font-weight: 500;
                }
            }

            .ant-modal-body {
                padding: 16px 24px 24px 24px;
            }

            .ant-modal-footer {
                display: none;
            }
        }
    }
}



:root {

    // overflow: hidden;
    &::-webkit-scrollbar {
        display: none;
    }

}