@import '../../../assets/theme/_mixins.scss';
@import '../../../assets/theme/styles.scss';

.ant-btn {
  @extend .typography--body--2;
  color: var(--primarywhite);
  background-color: var(--primary);
  border-radius: 4px;
  height: auto;
  border-color: var(--primary);
//  padding: 4% 0;
 width: fit-content;
  &.outline-button {
    @include bg_button_styles(3.5rem, auto); // Example mixin for outline button styles
    border: 1px solid var(--primary); // Customize border color for outline button
    color: var(--primary); // Customize text color for outline button
    background-color: transparent; // Transparent background for outline button
  }

  &.background-button {
    @include bg_button_styles(3.5rem, auto); // Example mixin for background button styles
    background-color: var(--primary); // Customize background color for background button
    color: var(--primarywhite); // Customize text color for background button
    border: none; // No border for background button
  }
}
