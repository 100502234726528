:root {
  display: flex;
  flex-direction: column;

  // -->textColors:

  --primary: #1F6C9F;
  --secondary:#2d80b5;
  --primarywhite: #fff;
  --black: #000000;
  --blackSecondary: #000000d9;
  --blackThird: #131313;
  --hash:#878787;
  --whiteSecond:#d3d3d3;
  // ..................................textColors-End.................................................
  // -->backgroundColors:

  --tableHead: #8EBAE3;
  --mainBackground: #F3F2EF;
  --backgroundTwo: #EDEDED;
  --backgroundThree:#f5f5f5;

  // ..................................backgroundColors-End.................................................
  // -->boxShadowColors:
  --darkgray:darkgray;

  // ..................................boxShadowColors-End.................................................

  --lineSeparator: #D9D9D9;
  --textInactive: #6D6666;

  //Additional Colors:
  --red: red;
  --redTwo: #DE2F2F;
  --redThree: #DE2F2F;
  --pink: #F5CCCD;
  --pinkTwo: #FFC2BE;
  --violet: #9C6AFF;
  --yellow: yellow;
  --yellowTwo: #ECFEC1;
  --yellowThird: #FBF2D8;

}