.result-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
  
    p {
      font-size: 1.2rem;
      color: #6c757d;
    }
  }
  
  .success-page {
    background-color: #e9f7ef;
  
    h1 {
      color: #28a745;
    }
  }
  
  .already-submitted-page {
    background-color: #fff3cd;
  
    h1 {
      color: #ffc107;
    }
  }