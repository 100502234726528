@import '../assets/theme/styles.scss';

.assesments_questionnaires_container {
    padding: 1.6% 2% 2% 2%;
    height: 100vh;
    overflow-y: auto;
    .assesments_questionnaires_info {
        background-color: var(--primarywhite);
        // width: 70%;
        // margin: 3% auto 5%;
        border-radius: 20px;
        padding: 4rem 4.5rem 3.5rem 4.5rem;
        margin-bottom: 5%;

        .header_block {
            h4 {
                @extend .typography--heading--4;
                margin: 0;
                color: var(--black);

                .anticon {
                    margin-right: 0.6rem;
                    cursor: pointer;
                }
            }
        }

        .questionnaires_content {
            width: 60%;
            margin: 3% auto 0%;

            .qtn_fields {
                margin-bottom: 2rem;

                p {
                    color: var(--black);
                    font-size: 1.4rem;
                    font-weight: 400;
                }

                .ant-card {
                    border-bottom: 0;
                    border-radius: 0;

                    .ant-card-head {
                        padding: 0 24px;
                        border-bottom: 0;

                        .ant-card-head-title {
                            color: #2f4f4f;
                            font-size: 1.2rem;
                            font-weight: 400;

                        }
                    }

                    .ant-card-body {
                        padding-top: 0;

                        h5 {
                            @extend .typography--heading--5;
                            color: var(--black);

                        }

                        h4 {
                            @extend .typography--heading--4;
                            color: var(--black);
                        }

                        h3 {
                            @extend .typography--heading--4;
                            color: var(--primary);
                        }

                        .ant-radio-group {
                            .ant-radio-wrapper {
                                font-size: 1.2rem;
                                display: block;
                                line-height: 1.6;

                                .ant-radio {
                                    .ant-radio-inner {
                                        width: 14.8px;
                                        height: 14.3px;
                                    }
                                }
                            }
                        }

                        .custom-checkbox {
                            .ant-checkbox-inner {
                                width: 15px;
                                height: 15px;
                            }

                            .ant-checkbox-wrapper {
                                display: flex;
                                margin-top: 0.7rem;

                                .ant-checkbox+span {
                                    @extend .typography--body--3;
                                    color: var(--black);
                                }
                            }

                            .ant-checkbox-wrapper+.ant-checkbox-wrapper {
                                margin-left: 0;
                            }

                        }
                    }
                }


                .ant-card:first-of-type {
                    border-radius: 5px 5px 0 0;
                }

                .ant-card:last-child {
                    border: 1px solid rgba(0, 0, 0, .06) !important;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;

                }



            }


        }

        .footer_block {
            display: flex;
            justify-content: flex-end;
            margin: 3rem 0 0rem 0;

            .ant-btn {
                height: 3.3rem;
                font-size: 1.2rem;
                width: 10rem;
            }

            .prev_btn {
                border: 1.5px solid var(--primary);
                background-color: unset;
                color: var(--primary) !important;

            }
        }


    }
}

.no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  
    .ant-spin {
      margin-bottom: 20px;
    }
  
    .message {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      max-width: 80%;
      line-height: 1.5;
    }
  }
@media screen and (max-width:1024px) {
    .assesments_questionnaires_container {
        padding: 1.6% 2% 5% 2%;

        .assesments_questionnaires_info {
            .questionnaires_content {
                width: 70%;
            }
        }
    }

    .ant-modal.submitForm_modal {
        width: 33% !important;

        .ant-modal-content {
            .ant-modal-body {
                .submitForm_content {
                    p {
                        font-size: 1.4rem;
                    }

                    .ant-btn {
                        height: 4rem;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
    .ant-modal.noteConformation_modal {
        width: 44% !important;
        .ant-modal-content{
            .ant-modal-body{
                .noteConformation_content{
                    .noteConformation_info{
                        p{
                            font-size: 1.4rem;
                        }
                    }
                } 
            }
        }
    }
    .ant-modal.addNote_modal {
        width: 88% !important;
    }
}

@media screen and (max-width:768px) {
    .assesments_questionnaires_container {
        padding: 1.6% 2% 9% 2%;

        .assesments_questionnaires_info {
            .questionnaires_content {
                width: 86%;
            }
        }
    }
}