// _typography.scss



// Typography mixins
// @mixin typography-body($font-size, $line-height, $font-weight, $color) {
//   font-family: $primary-font;
//   font-size: $font-size;
//   line-height: $line-height;
//   font-weight: $font-weight;
//   color: $color;
// }

// @mixin typography-heading($font-size, $line-height, $font-weight, $color) {
//   @include typography-body($font-size, $line-height, $font-weight, $color);
// }

// // Define specific typography styles
// .typography--body--1 {
//   @include typography-body(12px, 16px, 400, #283636);
// }

// .typography--heading--1 {
//   @include typography-heading(100px, 40px, 600, red);
// }

// .typography--heading--2 {
//   @include typography-heading(24px, 32px, 600, #283636);
// }

// .typography--heading--3 {
//   @include typography-heading(18px, 28px, 600, #283636);
// }

// .typography--heading--4 {
//   @include typography-heading(16px, 20px, 600, #283636);
// }

// .typography--heading--5 {
//   @include typography-heading(14px, 20px, 600, #283636);
// }

// .typography--heading--6 {
//   @include typography-heading(12px, 16px, 600, #283636);
// }

// ............................................................................................................
// _typography.scss



// Typography mixins
@mixin typography-body($font-size, $line-height, $font-weight, $color) {
  font-family: $primary-font;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  color: $color;
}

@mixin typography-heading($font-size, $line-height, $font-weight, $color) {
  @include typography-body($font-size, $line-height, $font-weight, $color);
}

// Define specific typography styles


.typography--heading--1 {
  @include typography-heading(2.4rem, 1.5, 500, var(--black));
}

.typography--heading--2 {
  @include typography-heading(2rem, 1.5, 500, var(--primary));
}

.typography--heading--3 {
  @include typography-heading(1.8rem, 1.5, 500, var(--primary));
}
.typography--heading--3-bold {
  @include typography-heading(1.8rem, 1.5, 600, var(--primary));
}

.typography--heading--4 {
  @include typography-heading(1.6rem, 1.5, 500, var(--primary));
}
.typography--heading--4-bold{
  @include typography-heading(1.6rem, 1.5, 600, var(--black));

}
.typography--heading--5 {
  @include typography-heading(1.4rem, 1.5, 500, var(--black));
}
.typography--heading--6 {
  @include typography-heading(1.3rem, 1.5, 500, #6d6666);
}



.typography--body--1 {
  @include typography-body(1.4rem, 1.5, 400, #6d6666);
}
.typography--body--2 {
  @include typography-body(1.3rem, 1.5, 400, var(--black));
}
.typography--body--2-light {
  @include typography-body(1.3rem, 1.5, 300, #131313);
} 

.typography--body--3 {
  @include typography-body(1.2rem, 1.5, 400, var(--primary));
}
.typography--body--3-light {
  @include typography-body(1.2rem, 1.5, 300, var(--black));
}

.typography--body--4 {
  @include typography-body(1.1rem, 1.5, 400, var(--red));
}
.typography--body--4-light {
  @include typography-body(1.1rem, 1.5, 300, var(--black));
}

