.error-boundary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  
    .ant-result {
      max-width: 500px;
      text-align: center;
    }
  }