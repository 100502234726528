// NotFoundPage.scss
.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
  
    h1 {
      font-size: 2.5rem;
      color: #dc3545;
    }
  
    p {
      font-size: 1.2rem;
      color: #6c757d;
    }
  }
  