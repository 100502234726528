.ant-radio-group {
    .ant-radio-wrapper {
        font-size: 1.3rem;
line-height: 2.3;
        .ant-radio {
            .ant-radio-checked .ant-radio-inner {
                border-color: var(--primary-color);
            }

            .ant-radio-inner {
                width: 16px;
                height: 16px;
            }
        }
    }
    // CustomRadio.scss

.ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-checked {
      background-color: var(--primary); // Change this to the color you want
      color: #fff; // Change this to the text color you want
      border-color: var(--primary-color); // Change this to the border color you want
    }
  
    &:hover {
      background-color: var(--primary); // Change this to the hover color you want
      color: #fff; // Change this to the text color you want
      border-color: var(--primary); // Change this to the border color you want
    }
  }
  
}
