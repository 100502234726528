/* _base.scss */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", CamptonBook, sans-serif;
  overflow: hidden;
}
html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px
  }
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
  @include respond(phone) {
    // Apply styling specific to the phone breakpoint
    font-size: 45%; // Adjust the value as needed
  }
  @include respond(tab-land) {
    // width < 1200px?
    font-size: 56.25%; // 1 rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    // width < 900px?
    font-size: 50%; // 1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px, 12/16
  }
}

:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}
