.ant-notification {
    .ant-notification-notice {
        width: 330px;
        padding: 15px 10px 13px 13px;
        background:var(--primarywhite);
        border-radius: 5px;    
    }
    .ant-notification-notice-icon {
        font-size: 1.8rem;
        line-height: 29px;

    }
    .ant-notification-notice-close {
        position: absolute;
        top: 11px;
        right: 22px;
        color: rgba(0,0,0,.45);
    }
    .ant-notification-notice-with-icon  {
        .ant-notification-notice-message{
            margin-left: 36px;
            font-size: 13.5px;
            margin-bottom: 4px;
    
        }
        .ant-notification-notice-description{
            margin-left: 36px;
            font-size: 13.5px;
        }
       
    }
}






